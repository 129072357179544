import { uniqBy } from 'lodash'
import Console from '@c/console'
import { getClient, getResponseData } from '../../../store/src/axios'

const organisationClient = getClient('/organisations')

export async function getOrganisations({ query, page_size = 10 }) {
  const response = await organisationClient.get(`/`, {
    params: { search: query, page_size }
  })
  return getResponseData(response)
}

export async function getOrganisation({ organisation_id }) {
  const response = await organisationClient.get(`/${organisation_id}/`)
  return getResponseData(response)
}

export async function getOrganisationSuggestions({ query }) {
  const response = await organisationClient.get(`/suggestions/`, {
    params: { search: query }
  })
  return getResponseData(response)
}

// Organisations

export async function searchOrganisations({
  query,
  include_suggestions = true
}) {
  let companies = []

  if (!query) {
    return companies
  }

  const [organisationsResponse, organisationSuggestions] = await Promise.all(
    [
      getOrganisations({ query }),
      include_suggestions
        ? getOrganisationSuggestions({ query })
        : new Promise((res) => res([]))
    ].map((p) =>
      p.catch((e) => {
        Console.debug('error in searchOrganisations', e)
        return e
      })
    )
  )
  const isValid = (res) =>
    !!res && !(res instanceof Error) && Array.isArray(res)

  companies = uniqBy(
    [
      ...companies,
      ...(isValid(organisationsResponse) ? organisationsResponse : [])
    ],
    'linkedin_url'
  )
  companies = uniqBy(
    [
      ...companies,
      ...(isValid(organisationSuggestions) ? organisationSuggestions : [])
    ],
    'linkedin_url'
  )
  return companies
}

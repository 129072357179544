<template>
  <div class="org-item" :class="{ bordered }">
    <div class="org-item-avatar">
      <Avatar
        :user="{ avatar: loading ? '' : item.icon || item.logo }"
        fallback-icon="company"
        size="m"
      />
      <div v-if="loading" class="org-item-avatar-loading">
        <img
          src="@/assets/icons/spinner.svg"
          alt=""
          class="org-item-avatar-loading-spinner"
        />
      </div>
    </div>
    <div class="org-item-info" :class="{ loading }">
      <div class="org-item-name">
        <b-skeleton v-if="loading" height="1.2rem" width="min(100%, 15rem)" />
        <span v-else>{{ item.name }}</span>
        <img
          v-if="!loading && item.accountId"
          src="@/assets/icons/check-circle.svg"
          alt=""
          class="org-item-check"
        />
        <img
          v-if="!loading && item.linkedin_url"
          src="@/assets/logo/linkedin.svg"
          alt=""
          class="org-item-linkedin"
          @click.stop="() => handleOpenExternal(item.linkedin_url)"
        />
      </div>
      <b-skeleton v-if="loading" height="1.2rem" width="min(70%, 11.5rem)" />
      <p
        v-else-if="item.domain"
        class="org-item-domain"
        @click.stop="() => handleOpenExternal(item.domain)"
      >
        {{ item.domain }}
      </p>
    </div>
    <Button
      v-if="!loading && removable"
      icon="close"
      type="white"
      size="xxs"
      @click="() => $emit('remove')"
    />
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import Avatar from '@c/library/Avatar.vue'

export default {
  name: 'OrganisationSearchItem',
  components: { Button, Avatar },
  props: {
    item: {
      type: Object,
      default: () => undefined
    },
    removable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleOpenExternal(url) {
      const link = url.startsWith('http') ? url : `https://${url}`
      window.open(link, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.org-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;

  &.bordered {
    padding: 0.5rem;
    border-radius: 8px;
    border: 1px solid rgba(#000, 8%);
  }

  &-avatar {
    position: relative;

    &-loading {
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border-radius: 999rem;
      background: rgba(#fff, 0.25);
      display: flex;
      justify-content: center;
      align-items: center;

      &-spinner {
        height: 1.2rem;
        width: 1.2rem;
        animation: spin 1s linear infinite;
      }
    }
  }

  &-info {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;

    &.loading {
      gap: 2px;
    }
  }

  &-name {
    font-weight: 600;
    display: flex;
    flex-flow: row nowrap;
    gap: 0.5rem;
    align-items: center;
  }

  &-check {
    height: 1rem;
    filter: brightness(0) saturate(100%) invert(25%) sepia(95%) saturate(2915%)
      hue-rotate(217deg) brightness(97%) contrast(103%);
  }

  &-linkedin {
    height: 1.1rem;
    cursor: pointer;
  }

  &-domain {
    color: $primary;
    cursor: pointer;
    width: fit-content;

    &:hover {
      text-decoration: underline;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
